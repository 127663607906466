import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
  accountLink: '',
  accountNumber: '',
  bankAccType: 'kontonummer',
  bankCountry: '',
  clearingNumber: '',
  companyRegistrationNumber: '',
  vatId: '',
  ctolCertify: false,
  wclCertify: false,
  companyName: '',
  oneFlowContractId: '',
  email: '',
  firstname: '',
  iban: '',
  lastname: '',
  logotype: '',
  phone: '',
  qrCode: '',
  userImage: '',
  stripeConnectVisited: false,
  onboardingCompleted: false,
  stripeRequiresInfo: false,
  stripeRequiresDocument: false,
  oneFlowEmail: '',
  detailsSubmitted: false,
  oneFlowSigned: false,
});

export const getters = {
  getField,
};

export const mutations = {
  updateField,
  setAccountLink(s, accountLink) {
    s.accountLink = accountLink;
  },
  setStripeConnectVisited(s, visited) {
    s.stripeConnectVisited = visited;
  },
  setQrCode(s, qrCode) {
    s.qrCode = qrCode;
  },
  setOneFlowContractId(s, oneFlowContractId) {
    s.oneFlowContractId = oneFlowContractId;
  },
  setStripeOnboardingCompleted(s, onboardingCompleted) {
    s.onboardingCompleted = onboardingCompleted;
  },
  setStripeRequiresInfo(s, stripeRequiresInfo) {
    s.stripeRequiresInfo = stripeRequiresInfo;
  },
  setStripeRequiresDocument(s, stripeRequiresDocument) {
    s.stripeRequiresDocument = stripeRequiresDocument;
  },
  setDetailsSubmitted(s, detailsSubmitted) {
    s.detailsSubmitted = detailsSubmitted;
  },
  setOneFlowSigned(s, oneFlowSigned) {
    s.oneFlowSigned = oneFlowSigned;
  },
  setAddress(s, address) {
    s.address = address;
  },
  setCompanyName(s, companyName) {
    s.companyName = companyName;
  },
  setBankCountry(s, bankCountry) {
    s.bankCountry = bankCountry;
  },
};

export const actions = {
  async login({ commit, state, rootState }, formData) {
    const { phone, code, country } = rootState;
    const { bankCountry } = state;

    try {
      const { authorization, accountLink, userId } = await this.$axios.$post(
        '/api/login-company',
        {
          phone,
          code,
        },
        { headers: { 'x-tiptapp-country': country } }
      );

      commit('setJwt', authorization, { root: true });
      commit('setLoggedIn', true, { root: true });
      commit('setAccountLink', accountLink);
      commit('setBankCountry', bankCountry || country);
      commit('setUserId', userId, { root: true });

      this.$router.push({ path: 'company' });
    } catch (error) {
      console.log(error);
      this.$router.push({ path: 'error' });
    }
  },
  async postDetails({ commit, state, rootState }, formData) {
    const { jwt: authorization, country, userId } = rootState;

    const {
      ctolCertify,
      wclCertify,
      companyRegistrationNumber,
      vatId,
      firstname,
      lastname,
      bankCountry,
      clearingNumber,
      iban,
      accountNumber,
      email,
      logotype,
    } = state;

    let detailsPayload = {
      firstname,
      lastname,
      bankCountry,
      email,
      ctolCertify,
      wclCertify,
      companyRegistrationNumber,
      vatId,
      logotypeImageUri: logotype,
    };

    if (iban) {
      detailsPayload.iban = iban;
    } else {
      detailsPayload = { ...detailsPayload, accountNumber, clearingNumber };
    }

    try {
      const response = await this.$axios.$post('/api/company-details', detailsPayload, {
        headers: { 'x-tiptapp-country': country, authorization, 'x-tiptapp-uid': userId },
      });

      this.$router.push({ path: 'company' });
    } catch (error) {
      console.log(error);
      this.$router.push({ path: 'error' });
    }
  },
  async getAccount({ commit, state, rootState }, formData) {
    const { jwt: authorization, email, phone } = rootState;
    const { firstname, lastname } = state;

    const {
      accountLink,
      companyName,
      address,
      contactFirstName,
      contactLastName,
      contactEmail,
      contactPhone,
      stripeVerified,
      stripeRequiresInfo,
      stripeRequiresDocument,
      stripeRequiresExternalAccount,
    } = await this.$axios.$get(`/api/get-company-account`, {
      headers: { authorization },
    });

    commit('setAccountLink', accountLink);
    commit(
      'setStripeOnboardingCompleted',
      stripeVerified ||
        (stripeRequiresExternalAccount && !stripeRequiresInfo && !stripeRequiresDocument)
    );
    commit('setStripeRequiresInfo', stripeRequiresInfo);
    commit('setStripeRequiresDocument', stripeRequiresDocument);
    commit('setDetailsSubmitted', stripeVerified);
    commit('setAddress', address);
    commit('setCompanyName', companyName);
    commit('updateField', { path: 'firstname', value: firstname || contactFirstName });
    commit('updateField', { path: 'lastname', value: lastname || contactLastName });
    commit('updateField', { path: 'email', value: email || contactEmail });
    commit('updateField', { path: 'phone', value: phone || contactPhone });
  },
  async sendOneFlowForm({ commit, state, rootState }) {
    const { jwt: authorization, country } = rootState;
    const {
      oneFlowContractId: contractId,
      oneFlowEmail: signatoryEmail,
      address: { line1, zip, city },
      companyName,
      firstname: contactFirstName,
      lastname: contactLastName,
      phone: contactPhone,
      email: contactEmail,
    } = state;

    const result = await this.$axios.$post(
      '/api/oneflow-form',
      {
        contractId: '',
        signatoryEmail,
        address: { line1, zip, city },
        companyName,
        contactFirstName,
        contactLastName,
        contactPhone,
        contactEmail,
        country,
        countryName: this.app.i18n.t(`countries.${country.toLowerCase()}`)
      },
      {
        headers: { authorization },
      }
    );

    commit('setOneFlowContractId', result.contractId);
    commit('setQrCode', result.qrCode);

    this.$router.push({ path: 'company-success' });
  },
  async getOneFlowFormStatus({ commit, state, rootState }) {
    const { jwt: authorization } = rootState;
    const { oneFlowContractId: contractId } = state;

    if (contractId) {
      const { signed } = await this.$axios.$get(`/api/oneflow-form?contractId=${contractId}`, {
        headers: { authorization },
      });

      commit('setOneFlowSigned', signed === true);
    }
  },
};
